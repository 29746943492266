.app-logo-header{
  display: flex;
}
.app-logo-header.navbar.fixed-top{
  background: #023047 !important;
  z-index: 1000;
  height: 55px;
  padding-left: 16px!important;
  padding-right: 16px!important;

}
.app-nav-margin {
  margin-left: 138.5px;
}