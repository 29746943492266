.components-grid {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 34px;
  margin-top: 60px;
}
.component-grid-container {
  display: flex;
  flex-direction: row;
  position: relative;
  right: 20px;
  margin-bottom: 20px;
}
.component-grid-container2 {
  display: flex;
  flex-direction: row;
  position: relative;
  right: 20px;
  top: 20px;
}
.component-grid-container3 {
  display: flex;
  flex-direction: row;
  position: relative;
  right: 20px;
  top: 10px;
  margin-bottom: 20px;
}

.navLink{
  color: black;
}
.navLinkActive {
  color: #007bff;
}
.component-type-desc {
  width: 100%;
  font-size: 24px;
  font-weight: 700;
  color: #023047;
  margin-bottom: 20px;
  margin-left: 6px;
}