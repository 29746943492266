
* {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: row;
  margin-top: 68px;
  transition: all 0s ease;
  position: absolute;
  height: 78%;
  width:100%;
  top: 32px;
}

.sideBar {
  height: 1060px;
  width: 280px;
}

.appExpand {
  position: absolute;
  left: -17%;
  width: 119%;
  overflow: hidden;
  overflow-y: scroll;
}

.MuiTableCell-alignRight {
  text-align: left !important;
}

.MuiSimpleToolTip-wrapper {
  display: flex;
  gap: 1rem;
  margin: 1rem 0;
  align-items: center;
}

.MuiInputBase-input {
  line-height: 22px !important;
}

.btn-custom-disabled {
  background-color: var(--bs-gray) !important;
  border-color: var(--bs-gray) !important;
}

.active.page-item a {
  background-color: #007bff !important;
  color: #fff !important;
}

.sidebar-body.card-body {
  padding:0rem !important;
}

.border-none {
  border: none !important;
}

.column-width {
  min-width: 9.125rem !important;
}

.column-width-filtertable {
  min-width: max-content;
}

.column-end-width {
  min-width: 15rem;
}

.hover-indicate input {
  border: 1px solid var(--bs-gray) !important;
}

.hover-indicate:hover input {
  border: 1px solid var(--bs-gray) !important;
}


.scrollable-horizontal {
  max-width: max-content;
  overflow-x: scroll;
}

.scrollable-vertical {
  max-height: 25rem;
  overflow-y: scroll;
}

.pre-code {
  margin-right: 20px !important;
  background: #fff !important;
}

.empty-space {
  display: inline-block;
  width: 22px;
}

.input-disabled {
  background-color: #fff !important;
  cursor: text;
}

.input-group>.form-control {
  min-width: 6rem !important;
}

.Example {
  background-color: #f5f5f5;
}

.Example input,
.Example button {
  font: inherit;
}

.Example header {
  background-color: #0088d1;
  padding: 1.25rem;
  color: white;
}

.Example__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.625rem 0;
  padding: 0.625rem;
  max-height: 25rem;
  overflow-y: auto;
}

.Example__container__load {
  margin-top: 1rem;
}

.breadcrumb-item-custom-1+.breadcrumb-item-custom-1::before {
  content: var(--bs-breadcrumb-divider, ">") !important;
}

.breadcrumb-item-custom-2+.breadcrumb-item-custom-2::before {
  content: var(--bs-breadcrumb-divider, "-") !important;
}