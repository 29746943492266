@import url('https://maxcdn.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css');
@import url('https://cdn.jsdelivr.net/npm/gijgo@1.9.10/css/gijgo.min.css');

.btnCompoWrap {
  display: flex;
  flex-direction: column;
  margin-left: 300px;
  margin-top: 20px;
  width:100%;
  padding:0 50px;
  overflow-y: scroll;
  padding-left:20px;
}

.btn-title {
  margin: 10px 0 20px;
  font-weight: 700;
  text-align: left;
  font-size: 18px;
}

.step-tabs-wrapper {
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: 6px;
  margin-bottom: 60px;
  margin-left: -7px;
  position: relative;
  width:100%;
}

badge#code-demo-tab-tab-demo,
badge#code-demo-tab-tab-code {
  border-bottom: 1px solid #dee2e6;
  min-width: 130px;
  text-align: center;
  height: 45px;
  padding: 10px 15px !important;
  background: #fff;
  color: #000;
}

badge#code-demo-tab-tab-demo.active,
badge#code-demo-tab-tab-code.active {
  color: #000;
  opacity: 1 !important;
  font-weight: 700;
  background: #f5f5f5;
}

badge#code-demo-tab-tab-demo.active:hover,
badge#code-demo-tab-tab-code.active:hover {
  background-color: #fff;
  transition: 0.7s;
}

.demo-wrapper {
  text-align: left;
  margin-top: 15px;
  margin-left: 25px;
}

.compo-description {
  margin-top: 25px;
}

.jsx-code-wrapper {
  margin-top: 20px;
  margin-left: 20px;
  text-align: left;
}