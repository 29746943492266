.app-breadcrumbs {
  position: fixed;
  top: 55px;
  right: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  height: 45px;
  z-index: 1000;
  padding: 10px 20px 12px;
  background-color: #f5f5f5;
  border-radius: 0;
  border-bottom: 1px solid #eaeaea;

  .MuiBreadcrumbs-ol li:nth-child(6) {
    display: none;
  }
}

.componentSearch {
  font-family: "Material Icons";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  top: 16px;
  right: 42px;
  position: absolute;
  color: #5f5f67;
}

.componentSearchToggled {
  font-family: "Material Icons";
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  top: 16px;
  right: 456px;
  position: absolute;
  pointer-events: none;
  color: #5f5f67;
}
.component-search-box {
  position: absolute !important;
  top: 6px;
  right: 31px;
  width: 384px;
  bottom: 5.5px;
}
.meterial-icons {
  font-size: 20px!important;
  margin-top: 4px!important;
  margin-right: 10px;
}
.closeIconClass{
  font-size: 16.9px;
  position: absolute;
  cursor: pointer;
  color: #333!important;
  right: 5px;
  margin-bottom: 4px;
}
.breadLinkActive {
  text-decoration: none !important;
  color: rgb(0, 136, 209);
}
.MuiInput-underline:after {
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  transform: scaleX(0);
  transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  border-bottom: 2px solid #039be5 !important;
  pointer-events: none;
}

.MuiInputBase-input {
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 2.1876em;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
  text-transform: capitalize !important;
  line-height: 10px !important;
}